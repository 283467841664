export const hyphenToCamel = (str) => {
  return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
}

// WordPressのスラッグ形式に変換
export const formatWPSlug = (str) => {
  // 大文字アルファベットを小文字アルファベットに変換
  let convertedText = str.toLowerCase();
  // 半角スペースと「.」を半角ハイフンに変換
  convertedText = convertedText.replace(/[\s.]/g, '-');

  return convertedText;
}